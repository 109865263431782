import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import Logo from "../../../images/header.jpeg"

class ServiceSlider1 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [], // Veriyi boş bir dizi olarak başlat
			imageUrl: 'https://aktifguvenlikapi.stechomeyazilim.info/upload/',
			isModalOpen: false,
		};
		this.modalRef = React.createRef();
	}

	componentDidMount() {
		const apiUrl = "https://getjob.stechomeyazilim.info:5101/getAboutAktif/select";

		fetch(apiUrl)
			.then((response) => response.json())
			.then((data) => {
				this.setState({ data }); // Fetch edilen veriyi state'e atar
			})
			.catch((error) => {
				console.error("API'den veri çekme hatası:", error);
			});
	}

	openModal = () => {
		this.setState({ isModalOpen: true });
		document.addEventListener('mousedown', this.handleClickOutside);
	}

	closeModal = () => {
		this.setState({ isModalOpen: false });
		document.removeEventListener('mousedown', this.handleClickOutside);
	}

	handleClickOutside = (event) => {
		if (this.modalRef.current && !this.modalRef.current.contains(event.target)) {
			this.closeModal();
		}
	}

	render() {
		const { isModalOpen } = this.state;

		const modalStyle = {
			position: 'fixed',
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
			background: 'rgba(0, 0, 0, 0.5)',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			zIndex: 999,
		};

		const contentStyle = {
			background: 'white',
			padding: '20px',
			borderRadius: '5px',
			boxShadow: '0 0 10px rgba(0, 0, 0, 0.6)',
			width: '30%',
			height: '80%',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		};

		const closeButtonStyle = {
			position: 'absolute',
			top: '80px',
			right: '10px',
			cursor: 'pointer',
			color: 'red',
			zIndex: 9999,
		};

		const settings = {
			dots: false,
			infinite: true,
			speed: 500,
			slidesToShow: 4,
			slidesToScroll: 1,
			responsive: [
				{
					breakpoint: 1199,
					settings: {
						slidesToShow: 3,
					}
				},
				{
					breakpoint: 991,
					settings: {
						slidesToShow: 2,
					}
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 1,
					}
				}
			]
		};

		return (
			<>
				<Slider {...settings} className="slider-sp0 arrow-none slider-process">
					<div className="slider-item">
					</div>
					<div className="slider-item">
						<div className="process-box">
							<h5 className="title"><i className="ti-world"></i> Biz Kimiz</h5>
							<p>Aktif Güvenlik Hakkında</p>
							<Link to="/hakkimizda" className="process-btn">Devamı <i className="ti-arrow-right"></i></Link>
						</div>
					</div>

					<div className="slider-item">
						<div className="process-box">
							<h5 className="title"><i className="ti-panel"></i> Bize Ulaşın</h5>
							<p>Bizimle iletişim kurmak ister misiniz?</p>
							<Link to="/iletisim" className="process-btn">Devamı<i className="ti-arrow-right"></i></Link>
						</div>
					</div>
					<div className="slider-item">
						<div className="process-box">
							<img src={Logo} />
							<Link onClick={this.openModal} className="process-btn">Devamı<i className="ti-arrow-right"></i></Link>
						</div>
					</div>
				</Slider>

				{isModalOpen && (
					<div style={modalStyle}>
						<div style={contentStyle} ref={this.modalRef}>
							<img style={{ width: '70%' }} src={`https://aktifguvenlikapi.stechomeyazilim.info/upload/${this.state.data[0].Document}`} />
							<button style={closeButtonStyle} onClick={this.closeModal}>x</button>
						</div>
					</div>
				)}
			</>
		);
	}
}

export default ServiceSlider1;

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Accordion, Card, Header, Button } from 'react-bootstrap';

// Layout
import Header1 from '../layout/header/header1';
import Footer1 from '../layout/footer/footer1';
import IconPdf from '../../images/icon/pdf.png';
import IconDoc from '../../images/icon/doc.png';
import AboutPic3 from '../../images/contact.jpeg';
import HeadPhones from '../../images/icon/headphones.png';
// Elements
import SolutionSidebar from '../elements/services/solution-sidebar';
import ReactHtml from 'raw-html-react';
import base64 from 'react-native-base64'
// Images
import Banner1 from '../../images/banner/bann.jpeg';
import ServicesPic1 from '../../images/services/pic1.jpg';
import ServicesPic2 from '../../images/services/pic2.jpg';

class ServicesDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			data2: [],
			data3: [],
			imageUrl: 'https://aktifguvenlikapi.stechomeyazilim.info/upload/',
		};
	}

	componentDidMount() {
		const apiUrl = "https://getjob.stechomeyazilim.info:5101/getProductCategoryAktif/select/";

		fetch(apiUrl)
			.then((response) => response.json())
			.then((data) => {
				this.setState({ data });
			})
			.catch((error) => {
				console.error("API'den veri çekme hatası:", error);
			});

		const apiUrl2 = "https://getjob.stechomeyazilim.info:5101/getContactAktif/select/";

		fetch(apiUrl2)
			.then((response) => response.json())
			.then((data2) => {
				this.setState({ data2 });
			})
			.catch((error) => {
				console.error("API'den veri çekme hatası:", error);
			});


		const apiUrl3 = "https://getjob.stechomeyazilim.info:5101/getProductAktif/select/" + this.props.location.state._item.ID;

		fetch(apiUrl3)
			.then((response) => response.json())
			.then((data3) => {
				this.setState({ data3 });
				console.log('Data3:', data3); // Log the entire data received from the API.

				// Now, let's log the Product_Category_Title for each item in data3.
				data3.forEach((item) => {
					console.log('Product Category Title:', item.ProductSubCategory.Product_Category_Title);
				});
			})
			.catch((error) => {
				console.error("API'den veri çekme hatası:", error);
			});

		console.log('idi', this.props.location.state._item.ID);
	}
	render() {

		const { _item, _item2 } = this.props.location.state;
		return (
			<>
				<Header1 />

				{/* <!-- Content --> */}
				<div className="page-content bg-white">

					<div className="page-banner ovbl-dark parallax" style={{ backgroundImage: "url(" + Banner1 + ")" }}>
						<div className="container">
							<div className="page-banner-entry">
								<h1 className="text-white">Ürünlerimiz</h1>
								<div className="breadcrumb-row">
									<ul className="list-inline">
										<li><Link to="/"><i className="fa fa-home"></i> Anasayfa</Link></li>

										{this.state.data3.length === 0 ? (
											<li>No data available for this category</li>
										) : (
											this.state.data3.map((item2) => (
												<li><Link to="/">{item2.ProductSubCategory.Product_Category_Title}</Link></li>
											))
										)}
										<li>{_item.Product_Title}</li>

									</ul>
								</div>
							</div>
						</div>
					</div>

					<div className="content-block" id="content-area">

						<div className="section-area section-sp2 bg-fix bg-white">
							<div className="container about-video">
								<div className="row">
									<div className="col-lg-4 col-md-5 m-b30 wow fadeInUp" data-wow-delay="0.2s">
										<aside className="sticky-top">
											<div className="widget">
												<ul className="service-list">


													{this.state.data.map((item2) => (

														item2.ProductSubCategory.map((item3) => (

															<li>
																<Link to={{ pathname: '/urunler', state: { _item: item3, } }}><span>{item3.Product_Category_Title}</span>
																	<i className="fa fa-angle-right"></i></Link>


															</li>))
													))}




												</ul>
											</div>
											<div className="widget">
												<div className="brochure-bx">
													<h5 className="title-head">İndir</h5>
													<Link to={_item.DocumentPdf} className="download-link">
														<img src={IconPdf} alt="" />
														<h5 className="title">Döküman İndir</h5>
														<span>İndir</span>
													</Link>

												</div>
											</div>
											<div className="widget">
												<div className="help-bx">
													<img className='self-center' src={AboutPic3} alt="" />
													<div className="info text-white">
														<img src={HeadPhones} alt="" />
														<h5 className="title">Size Nasıl Yardımcı Olabiliriz?</h5>
														<p>Yardıma ihtiyacınız varsa lütfen bizimle iletişime geçin.</p>
														{this.state.data2.map((item, index) => (
															<a href={`https://wa.me/${item.WhatsappNumber}`} className='text-white' key={index}>Whatsapp İçin Tıklayınız</a>
														))}
													</div>
												</div>
											</div>
										</aside>
									</div>
									<div className="col-lg-8 col-md-7 service-textarea wow fadeInUp portfolio-content" data-wow-delay="0.4s">


										<div className="container">
											<div className="row">
												<div className="col-lg-8">
													<div className="action-box blog-lg">

														<img style={{ height: 300 }} src={"https://aktifguvenlikapi.stechomeyazilim.info/upload/" + _item.ProductImage} alt="" />


													</div>
													<div className="heading-bx text-left m-b20">
														<h3 className="title-head m-b0">{_item.Product_Title}</h3>
														<span>Ürün Kodu: {_item.Product_Code} </span>
														<div className="ttr-separator sepimg"></div>
													</div>
							
												
													<p>	<ReactHtml  html={base64.decode(base64.decode(_item.ProductDesc))} /></p>

												</div>

											</div>
										</div>

									</div>
								</div>
							</div>
						</div>

					</div>

				</div>

				<Footer1 />

			</>
		);
	}
}

export default ServicesDetails;
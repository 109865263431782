import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Layout
import Header1 from '../layout/header/header1';
import Footer1 from '../layout/footer/footer1';
import Image1 from "../../images/testimonials/pic2.jpg"
// Elements
import AboutHead from '../elements/about/about-head';
import Testimonial2 from '../elements/testimonial/testimonial2';
import MissionVision from '../elements/mission-vision/mission-vision';
import Services2 from '../elements/services/services-2';
import Counter from '../elements/counter/counter';

// Images
import Banner1 from "../../images/banner/banner1.jpg"
import BackBg1 from "../../images/background/bg1.jpg"

class About1 extends Component {

	constructor(props) {
		super(props);
		this.state = {
			data: [],
			data2: [],
			imageUrl: 'https://aktifguvenlikapi.stechomeyazilim.info/upload/',
		};
	}

	componentDidMount() {
		const apiUrl = "https://getjob.stechomeyazilim.info:5101/getSSSEzgican/select/";

		fetch(apiUrl)
			.then((response) => response.json())
			.then((data) => {
				this.setState({ data });
			})
			.catch((error) => {
				console.error("API'den veri çekme hatası:", error);
			});

		const apiUrl2 = "https://getjob.stechomeyazilim.info:5101/getAboutAktif/select/";

		fetch(apiUrl2)
			.then((response) => response.json())
			.then((data2) => {
				this.setState({ data2 });
			})
			.catch((error) => {
				console.error("API'den veri çekme hatası:", error);
			});
	}

	render() {
		return (
			<>
				<Header1 />

				{/* <!-- Content --> */}
				<div className="page-content bg-white">
					{this.state.data2.map((item) => (
						<div className="page-banner ovbl-dark parallax" style={{ backgroundImage: "url(" + this.state.imageUrl + item.AboutBannerImage + ")" }}>
							<div className="container">
								<div className="page-banner-entry">
									<h1 className="text-white">Hakkımızda</h1>
									<div className="breadcrumb-row">
										<ul className="list-inline">
											<li><Link to="/"><i className="fa fa-home"></i> Anasayfa</Link></li>
											<li>Hakkımızda</li>
										</ul>
									</div>
								</div>
							</div>
						</div>))}

					<div className="content-block" id="content-area">
						{this.state.data2.map((item) => (
							<div className="section-area section-sp3 bg-white">
								<div className="container">
									<div className="about-head-bx wow fadeIn row">
										<div className="col-md-6">
											<div className="about-counter">
												<h5><span className="counter"></span> Aktif Güvenlik Sistemleri</h5>
												<h2 className="title">{item.AboutTitle}</h2>
											</div>
										</div>
										<div className="col-md-6">
											<div className="about-testimonial">
												<div className="testimonial-content">
													<p>{item.AboutDesc}</p>
												</div>

											</div>
										</div>
									</div>
								</div>
							</div>
						))}

						<Services2 />






					</div>
				</div>

				<Footer1 />

			</>
		);
	}
}

export default About1;
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Layout
import Header1 from '../layout/header/header1';
import Footer1 from '../layout/footer/footer1';

// Elements
import ClientLogo from '../elements/client-logo/client-logo-1';

// Images
import Banner1 from '../../images/banner/banner1.jpg';
import Icon1 from '../../images/icon/contact/icon1.png';
import Icon2 from '../../images/icon/contact/icon2.png';
import Icon3 from '../../images/icon/contact/icon3.png';

class GetInTouch extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			data2: [],
			data3: [],
			imageUrl: 'https://aktifguvenlikapi.stechomeyazilim.info/upload/',
		};
	}

	componentDidMount() {
		const apiUrl = "https://getjob.stechomeyazilim.info:5101/getAboutEzgican/select/";

		fetch(apiUrl)
			.then((response) => response.json())
			.then((data) => {
				this.setState({ data });
			})
			.catch((error) => {
				console.error("API'den veri çekme hatası:", error);
			});

		const apiUrl2 = "https://getjob.stechomeyazilim.info:5101/getContactAktif/select/";

		fetch(apiUrl2)
			.then((response) => response.json())
			.then((data2) => {
				this.setState({ data2 });
			})
			.catch((error) => {
				console.error("API'den veri çekme hatası:", error);
			});

		const apiUrl3 = "https://getjob.stechomeyazilim.info:5101/getAboutAktif/select/";

		fetch(apiUrl3)
			.then((response) => response.json())
			.then((data3) => {
				this.setState({ data3 });
			})
			.catch((error) => {
				console.error("API'den veri çekme hatası:", error);
			});
	}

	render() {
		const { data, data2 } = this.state;

		return (
			<>
				<Header1 />

				{/* <!-- Content --> */}
				<div className="page-content bg-white">
					{this.state.data3.map((item) => (
						<div className="page-banner ovbl-dark parallax" style={{ backgroundImage: "url(" + this.state.imageUrl + item.SSSBannerImage + ")" }}>
							<div className="container">
								<div className="page-banner-entry">
									<h1 className="text-white">İletişim</h1>
									<div className="breadcrumb-row">
										<ul className="list-inline">
											<li><Link to="/"><i className="fa fa-home"></i> Anasayfa</Link></li>
											<li>İletişim</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					))}

					<div className="content-block" id="content-area">
						<div className="section-area bg-gray section-sp2">
							<div className="container">
								<div className="row">
									<div className="col-lg-8 col-md-7">
										<div className="heading-bx">
											<h6 className="title-ext m-b0">İLETİŞİM</h6>
											<h3 className="title-head m-b0">Bize Ulaşın</h3>
											<div className="ttr-separator sepimg"></div>
										</div>
										<div className="row m-b30">
											<div className="col-md-12">
												<form className="ajax-form form-area-box get-in-touch" action="script/contact.php">
													<div className="ajax-message"></div>
													<div className="row placeani">
														<div className="col-lg-12">
															<div className="form-group">
																<div className="input-group">
																	<label>İsim*</label>
																	<input name="name" type="text" className="form-control" required />
																</div>
															</div>
														</div>
														<div className="col-lg-6">
															<div className="form-group">
																<div className="input-group">
																	<label>Email*</label>
																	<input name="phone" type="email" className="form-control" required />
																</div>
															</div>
														</div>
														<div className="col-lg-6">
															<div className="form-group">
																<div className="input-group">
																	<label>Telefon Numaranız*</label>
																	<input name="phone" type="text" className="form-control" required />
																</div>
															</div>
														</div>
														<div className="col-lg-12">
															<div className="form-group">
																<div className="input-group">
																	<label>Mesajınız*</label>
																	<textarea className="form-control" required></textarea>
																</div>
															</div>
														</div>
														<div className="col-lg-12">
															<button name="submit" type="submit" value="Submit" className="btn button-md"> Mesaj Gönder</button>
														</div>
													</div>
												</form>
											</div>
										</div>
									</div>
									<div className="col-lg-4 col-md-5">
										<aside className="sticky-top left-border-1">
											<div className="widget">
												<h6 className="widget-title">Dİğer Sayfalar</h6>
												<ul className="service-list style-2">
													<li><Link to="about-1">Hakkımızda <i className="las la-question-circle"></i></Link></li>
													<li><Link to="faq-1">SSS<i className="las la-quote-left"></i></Link></li>
												</ul>
												<Link to="get-in-touch" className="btn btn-block m-t20">İLETİŞİM</Link>
											</div>
											<div className="widget">
												{data2.map((item) => (
													<ul className="contact-infolist" key={item.ID}>
														<li>
															<img src={Icon1} alt="" className="mCS_img_loaded" />
															<h6 className="contact-title">İletişim Numarası</h6>
															<p>{item.Contact_Phone} </p>
														</li>
														<li>
															<img src={Icon2} alt="" className="mCS_img_loaded" />
															<h6 className="contact-title">E-mail Adres</h6>
															<Link to="#">{item.Contact_MailAddress}</Link><br />
														</li>
														<li>
															<img src={Icon3} alt="" className="mCS_img_loaded" />
															<h6 className="contact-title">Adres</h6>
															<p>Kılınçarslan Mahallesi Şair Seyfi Sokak No:18</p>
														</li>
													</ul>
												))}
											</div>
										</aside>
									</div>
								</div>
							</div>
						</div>
						<div className="section-area section-sp2 bg-white">
							<div className="container wow fadeIn" data-wow-delay="0.8s">
								<div className="heading-bx text-center">
									<h6 className="title-ext m-b0">Partner</h6>
									<h2 className="title-head m-b0">Partnerlerimiz & Müşterilerimiz</h2>
									<div className="ttr-separator sepimg"></div>
								</div>
								<ClientLogo />
							</div>
						</div>
					</div>
					{/* Render the Google Maps iframe using dangerouslySetInnerHTML */}
					<div dangerouslySetInnerHTML={{ __html: '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3148.849769192035!2d32.47747317644641!3d37.887195871957246!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d085bc5503bba3%3A0xbe61ce5460cb0a84!2sAktif%20G%C3%BCvenlik!5e0!3m2!1sen!2str!4v1697716729138!5m2!1sen!2str" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>' }} />
				</div>
				<Footer1 />
			</>
		);
	}
}

export default GetInTouch;

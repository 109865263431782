import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Image1 from "../../../images/about/pic11.jpg"
import Image2 from "../../../images/portfolio/portfolio-1/image_7.jpg"

class About1 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [], // Initialize data as an empty array
			imageUrl: 'https://aktifguvenlikapi.stechomeyazilim.info/upload/',

		};
	}

	componentDidMount() {
		const apiUrl = "https://getjob.stechomeyazilim.info:5101/getAboutAktif/select";

		fetch(apiUrl)
			.then((response) => response.json())
			.then((data) => {
				this.setState({ data: data }); // Set the fetched data in the state
			})
			.catch((error) => {
				console.error("API'den veri çekme hatası:", error);
			});
	}
	render() {
		return (
			<>
				{this.state.data.map((item) => (
					<div className="row">
						<div className="col-md-6">
							<div className="about-img-box3">
								<div className="img1">
									<img src={this.state.imageUrl + item.ImgUrl} alt="" />
								</div>
								<div className="img2">
									<img src={this.state.imageUrl + item.AboutImageTwo} alt="" />
								</div>
							</div>
						</div>
						<div className="col-md-6">
							<div className="heading-bx m-b20">
								<h6 className="title-ext m-b0">HAKKIMIZDA</h6>
								<h2 className="title-head m-b0">{item.AboutTitle}</h2>
								<div className="ttr-separator sepimg"></div>
								<p className="head-px2">{item.AboutShortDesc}</p>
							</div>
							<Link to="hakkimizda" className="btn">Hakkımızda</Link>
						</div>
					</div>
				))}
			</>
		);
	}
}

export default About1;

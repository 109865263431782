import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SimpleReactLightbox, {SRLWrapper} from 'simple-react-lightbox';
import moment from 'moment';
import RecentPic1 from "../../../images/blog/recent-blog/pic1.jpg"
import RecentPic2 from "../../../images/blog/recent-blog/pic2.jpg"
import RecentPic3 from "../../../images/blog/recent-blog/pic3.jpg"
import GalleryPic1 from "../../../images/gallery/pic1.jpg"
import GalleryPic2 from "../../../images/gallery/pic2.jpg"
import GalleryPic3 from "../../../images/gallery/pic3.jpg"
import GalleryPic4 from "../../../images/gallery/pic4.jpg"
import GalleryPic5 from "../../../images/gallery/pic5.jpg"
import GalleryPic6 from "../../../images/gallery/pic6.jpg"

const content = [
	{
		thumb: GalleryPic1,
	},
	{
		thumb: GalleryPic2,
	},
	{
		thumb: GalleryPic3,
	},
	{
		thumb: GalleryPic4,
	},
	{
		thumb: GalleryPic5,
	},
	{
		thumb: GalleryPic6,
	}
]

const options = {
	settings: {
		overlayColor: "rgba(0,0,0,0.9)",
		backgroundColor: "#fe5a0e",
		slideAnimationType: 'slide',
	},
	buttons: {
		backgroundColor: "#fe5a0e",
		iconColor: "rgba(255, 255, 255, 1)",
		showAutoplayButton: false,
		showDownloadButton: false,
	},
	caption: {
		captionColor: "#a6cfa5",
		captionFontFamily: "Raleway, sans-serif",
		captionFontWeight: "300",
		captionTextTransform: "uppercase",
	}
};



class BlogSidebar extends Component{
	constructor(props) {
		super(props);
		this.state = {
		  data: [],
		  imageUrl :'https://aktifguvenlikapi.stechomeyazilim.info/upload/',
		};
	  }
	
	  componentDidMount() {
		const apiUrl2 = "https://getjob.stechomeyazilim.info:5101/getBlogAktif/select/";
	
		fetch(apiUrl2)
		  .then((response) => response.json())
		  .then((data) => {
			this.setState({ data });
		  })
		  .catch((error) => {
			console.error("API'den veri çekme hatası:", error);
		  });

	  }
	render(){
		return(
			<>
				<aside className="side-bar sticky-top aside-bx">
					
					<div className="widget recent-posts-entry">
						<h6 className="widget-title">son Bloglar</h6>
						<div className="widget-post-bx">


						{this.state.data.slice(0,3).map((item) => (
							<div className="widget-post clearfix">
								<div className="ttr-post-media"> <img src={this.state.imageUrl+item.BlogImage} width="200" height="143" alt=""/> </div>
								<div className="ttr-post-info">
									<div className="ttr-post-header">
										<h6 className="post-title"><Link to={{ pathname: '/blog-details-sidebar', state: { _item: item } }}>{item.BlogTitle}</Link></h6>
									</div>
									<ul className="media-post">
										<li><Link to={{ pathname: '/blog-details-sidebar', state: { _item: item } }}>{moment(item.Article_Date).format('YYYY-MM-DD')}</Link></li>
									</ul>
								</div>
							</div>
							   ))}
						</div>
					</div>
				
				</aside>
			</>
		);
	}
}

export default BlogSidebar;

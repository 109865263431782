import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Accordion, Card, Header, Button } from 'react-bootstrap';

// Layout
import Header1 from '../layout/header/header1';
import Footer1 from '../layout/footer/footer1';

// Elements
import SolutionSidebar from '../elements/services/solution-sidebar';
import GalleryPic1 from '../../images/gallery/pic1.jpg';
import GalleryPic2 from '../../images/gallery/pic2.jpg';
import GalleryPic3 from '../../images/gallery/pic3.jpg';
import GalleryPic4 from '../../images/gallery/pic4.jpg';
import GalleryPic5 from '../../images/gallery/pic5.jpg';
import GalleryPic6 from '../../images/gallery/pic6.jpg';
// Images
import Banner1 from '../../images/banner/banner1.jpg';
import ServicesPic11 from '../../images/services/pic11.jpg';
import ServicesPic12 from '../../images/services/pic12.jpg';
import ServicesPic13 from '../../images/services/pic13.jpg';
import IconPdf from '../../images/icon/pdf.png';
import IconDoc from '../../images/icon/doc.png';
import AboutPic3 from '../../images/contact.jpeg';
import HeadPhones from '../../images/icon/headphones.png';
class About1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            data2: [],
        };
    }

    componentDidMount() {

        const apiUrl = "https://getjob.stechomeyazilim.info:5101/getProductCategoryAktif/select/";

        fetch(apiUrl)
            .then((response) => response.json())
            .then((data2) => {
                this.setState({ data2 });
            })
            .catch((error) => {
                console.error("API'den veri çekme hatası:", error);
            });


        const apiUrl2 = "https://getjob.stechomeyazilim.info:5101/getContactAktif/select/";

        fetch(apiUrl2)
            .then((response) => response.json())
            .then((data) => {
                this.setState({ data });
            })
            .catch((error) => {
                console.error("API'den veri çekme hatası:", error);
            });

        // Cart Dropdown 
        var cartBtn = document.getElementById("cartBtn");
        var cartDropdown = document.querySelector(".cart-dropdown");

        cartBtn.addEventListener('click', function () {
            cartDropdown.classList.toggle("show");
        });

        // Search Form Popup
        var searchBtn = document.getElementById("quik-search-btn");
        var searchForm = document.querySelector(".nav-search-bar");
        var closeBtn = document.getElementById("search-remove");

        searchBtn.addEventListener('click', function () {
            searchForm.classList.add("show");
        });

        closeBtn.addEventListener('click', function () {
            searchForm.classList.remove("show");
        });

        // Mobile Menu sidebar function
        var btn = document.querySelector('.menuicon');
        var nav = document.querySelector('.menu-links');

        function toggleFunc() {
            btn.classList.toggle("open");
            nav.classList.toggle("show");
        }

        btn.addEventListener('click', toggleFunc);

        // Mobile Submenu open close function
        var navMenu = [].slice.call(document.querySelectorAll('.menu-links > ul > li'));
        for (var y = 0; y < navMenu.length; y++) {
            navMenu[y].addEventListener('click', function () { menuClick(this) });
        }

        function menuClick(current) {
            const active = current.classList.contains("open")
            navMenu.forEach(el => el.classList.remove('open'));

            if (active) {
                current.classList.remove('open')
                console.log("active")
            } else {
                current.classList.add('open');
                console.log("close")
            }
        }
    }
    render() {


        return (
            <>
                <Header1 />

                {/* <!-- Content --> */}
                <div className="page-content bg-white">

                    <div className="page-banner ovbl-dark parallax" style={{ backgroundImage: "url(" + Banner1 + ")" }}>
                        <div className="container">
                            <div className="page-banner-entry">
                                <h1 className="text-white">ÜRÜNLERİMİZ</h1>
                                <div className="breadcrumb-row">
                                    <ul className="list-inline">
                                        <li><Link to="/"><i className="fa fa-home"></i> Anasayfa</Link></li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="content-block" id="content-area">

                        <div className="section-area section-sp2 bg-fix bg-white">
                            <div className="container about-video">
                                <div className="row">
                                    <div className="col-lg-4 col-md-5 m-b30 wow fadeInUp" data-wow-delay="0.2s">
                                        <aside className="sticky-top">
                                            <div className="widget">
                                                <ul className="service-list">


                                                    {this.state.data2.map((item2) => (

                                                        item2.ProductSubCategory.map((item3) => (

                                                            <li>
                                                                <Link to={{ pathname: '/urunler', state: { _item: item3 } }}><span>{item3.Product_Category_Title}</span>
                                                                    <i className="fa fa-angle-right"></i></Link>


                                                            </li>))
                                                    ))}




                                                </ul>
                                            </div>

                                            <div className="widget">
                                                <div className="help-bx">
                                                    <img className='self-center' src={AboutPic3} alt="" />
                                                    <div className="info text-white">
                                                        <img src={HeadPhones} alt="" />
                                                        <h5 className="title">Size Nasıl Yardımcı Olabiliriz?</h5>
                                                        <p>Yardıma ihtiyacınız varsa lütfen bizimle iletişime geçin.</p>
                                                        {this.state.data.map((item, index) => (
                                                            <a href={`https://wa.me/${item.WhatsappNumber}`} className='text-white' key={index}>Whatsapp İçin Tıklayınız</a>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>

                                        </aside>
                                    </div>
                                    <div className="col-lg-8 col-md-7 service-textarea wow fadeInUp portfolio-content" data-wow-delay="0.4s">


                                        <div className="section-area bg-gray section-sp2">

                                            <div className="heading-bx text-center">
                                                <h6 className="title-ext m-b0">AKTİF GÜVENLİK</h6>
                    
                                                <div className="ttr-separator sepimg"></div>
                                            </div>
                                            <div className="row">

                                                {this.state.data2.slice(0,1).map((item3) => (
                                                    item3.ProductSubCategory.slice(0,1).map((item2)=>(
                                                    item2.Product.map((item5)=>(
                                                <div className="col-lg-4 col-md-6 col-sm-6 col-12 wow fadeIn" data-wow-delay="0.2s">
                                                    <div className="feature-container feature-bx1 m-1">
                                                        <div className="feature-lg text-white m-b20">
                                                            <img src={`https://aktifguvenlikapi.stechomeyazilim.info/upload/${item5.ProductImage}`} alt="" />
                                                        </div>
                                                        <div className="icon-content">
                                                            <h6 className="ttr-tilte ">{item5.Product_Title}</h6>
                                                            <p>Stok Kodu:{item5.Product_Code}</p>
                                                            <Link to={{ pathname: '/urundetay', state: { _item: item5 } }} className="btn-link">Detay</Link>
                                                        </div>
                                                        <div className="bg-img" style={{ backgroundImage: "url(" + `https://aktifguvenlikapi.stechomeyazilim.info/upload/${item5.ProductImage}` + ")" }}></div>
                                                    </div>
                                                </div>
                                                        ))
                                                ))
												))}


                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

                <Footer1 />

            </>
        );
    }
}

export default About1;
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";

class ClientLogo1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  componentDidMount() {
    const apiUrl = "https://getjob.stechomeyazilim.info:5101/getReferancesAktif/select/";

    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ data });
      })
      .catch((error) => {
        console.error("API'den veri çekme hatası:", error);
      });
  }

  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      centerMode: true, // Slaytları merkeze alır
      centerPadding: "0", // Merkezdeki slayt ile diğer slaytlar arasındaki boşluğu sıfıra ayarlar
      autoplay: true, // Otomatik kaydırma etkinleştirir
      autoplaySpeed: 3000, // Otomatik kaydırma hızı (ms cinsinden)
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
          }
        }
      ]
    };

    return (
      <div className="dots-style-1 arrow-none">
        <Slider {...settings}>
          {this.state.data.map((item, index) => (
            <div className="item" key={index}>
              <div className="client-logo text-center">
                <Link to="/partners">
                  <img
                    src={`https://aktifguvenlikapi.stechomeyazilim.info/upload/${item.ReferencesImage}`}
                    alt=""
                    className="mx-auto w-16 h-16" // Resimleri merkeze alır
                  />
                  <p className="text-blue-500 text-center"> {item.ReferencesTitle}</p> 
                </Link>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    );
  }
}

export default ClientLogo1;

import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Layout
import Header1 from '../layout/header/header1';
import Footer1 from '../layout/footer/footer1';

// Elements
import ClientLogo from '../elements/client-logo/client-logo-1';

// Images
import Banner1 from '../../images/banner/bann.jpeg';
import Icon1 from '../../images/icon/contact/icon1.png';
import Icon2 from '../../images/icon/contact/icon2.png';
import Icon3 from '../../images/icon/contact/icon3.png';


class Partners extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            data2: [],
            data3: [],
            data2ToShow: 5, // İlk olarak 6 öğe göster
            data3ToShow: 5, // İlk olarak 6 öğe göster
            showModal: false, // Modalın görüntülenip görüntülenmediğini izleyen bir durum.
            selectedImage: null, // Modalda görüntülenecek resmin URL'si.
        };
    }

    componentDidMount() {

        const apiUrl2 = "https://getjob.stechomeyazilim.info:5101/getCertificasAktif/select";

        fetch(apiUrl2)
            .then((response) => response.json())
            .then((data2) => {
                this.setState({ data2 });
            })
            .catch((error) => {
                console.error("API'den veri çekme hatası:", error);
            });
        const apiUrl3 = "https://getjob.stechomeyazilim.info:5101/getContactAktif/select/";

        fetch(apiUrl3)
            .then((response) => response.json())
            .then((data3) => {
                this.setState({ data3 });
            })
            .catch((error) => {
                console.error("API'den veri çekme hatası:", error);
            });

    }


    // Load More işlevi data2 için
    loadMoreData2 = () => {
        this.setState((prevState) => ({
            data2ToShow: prevState.data2ToShow + 5, // Her tıklamada 5 daha fazla öğe göster
        }));
    };

    openModal = (imageSrc) => {
        console.log("Opening modal");
        this.setState({ showModal: true, selectedImage: imageSrc });
    }

    closeModal = () => {
        console.log("Closing modal");
        this.setState({ showModal: false, selectedImage: null });
    }


    render() {
        return (
            <>
                <Header1 />
                {this.state.showModal && (
  <div className="center-modal">
    <div
      style={{
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center', // Dikey ortalama
        justifyContent: 'center', // Yatay ortalama
        zIndex: 100000,
      }}
    >
      <img
        src={this.state.selectedImage}
        alt="Modal Image"
        className="w-full"
        style={{
          display: 'block',
          maxWidth: '100%', // Resmi belirli bir genişlikte sınırlar
        }}
      />
      <span
        className="cursor-pointer text-2xl"
        onClick={this.closeModal}
        style={{
          color: 'white',
          cursor: 'pointer',
          position: 'absolute', // Kapatma düğmesini resmin üzerine yerleştirir
          top: 20, // İstediğiniz yükseklik ayarı
          right: 20, // İstediğiniz sağ kenar ayarı
        }}
      >
        x
      </span>
    </div>
  </div>
)}





                {/* <!-- Content --> */}
                <div className="page-content bg-white">

                    <div className="page-banner ovbl-dark parallax" style={{ backgroundImage: "url(" + Banner1 + ")" }}>
                        <div className="container">
                            <div className="page-banner-entry">
                                <h1 className="text-white">Sertifikalarımız</h1>
                                <div className="breadcrumb-row">
                                    <ul className="list-inline">
                                        <li><Link to="/"><i className="fa fa-home"></i> Anasayfa</Link></li>
                                        <li>Sertifikalarımız</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="content-block" id="content-area">

                        <div className="section-area bg-gray section-sp1">
                            <div className="container">
                                <div className="row">

                                    <div className="col-lg-8 col-md-7">
                                        <div className="heading-bx">
                                            <h6 className="title-ext m-b0">Aktİf Güvenlİk</h6>
                                            <h3 className="title-head m-b0">Sertifikalarımız</h3>
                                            <div className="ttr-separator sepimg"></div>
                                            <p className="head-px2">Aktif Güvenlik</p>
                                        </div>

                                        <div className="row m-b30">



                                            {this.state.data2.slice(0, this.state.data2ToShow).map((item, index) => (
                                                <div className="col-md-6 col-lg-4 col-6 m-b30">

                                                    <img
                                                        src={`https://aktifguvenlikapi.stechomeyazilim.info/upload/${item.ImgUrl}`}
                                                        alt=""
                                                        onClick={() => this.openModal(`https://aktifguvenlikapi.stechomeyazilim.info/upload/${item.ImgUrl}`)}
                                                    />

                                                </div>
                                            ))}






                                        </div>

                                        <button
                                            onClick={this.loadMoreData2}
                                            style={{
                                                backgroundColor: '#3498db',
                                                color: 'white',
                                                padding: '8px 16px',
                                                borderRadius: '4px',
                                                border: 'none',
                                                cursor: 'pointer',
                                                margin: '0 auto',  // Center the button horizontally
                                                display: 'block',  // Make it a block-level element

                                            }}
                                        >
                                            Daha Fazla Yükle
                                        </button>


                                    </div>
                                    <div className="col-lg-4 col-md-5">
                                        <aside className="sticky-top left-border-1">
                                            <div className="widget">
                                                <h6 className="widget-title">Dİğer Sayfalar</h6>
                                                <ul className="service-list style-2">
                                                    <li><Link to="about-1">Hakkımızda <i className="las la-question-circle"></i></Link></li>
                                                    <li ><Link to="faq-1">SSS<i className="las la-quote-left"></i></Link></li>
                                                    <li className="active"><Link to="partners">Referanslarımız<i className="las la-handshake"></i></Link></li>

                                                </ul>
                                                <Link to="get-in-touch" className="btn btn-block m-t20">İletişim</Link>
                                            </div>
                                            <div className="widget">
                                                <h6 className="widget-title">Bİze Ulaşın</h6>
                                                {this.state.data3.map((item) => (
                                                    <ul className="contact-infolist" key={item.ID}>
                                                        <li>
                                                            <img src={Icon1} alt="" className="mCS_img_loaded" />
                                                            <h6 className="contact-title">İletişim Numarası</h6>
                                                            <p>{item.Contact_Phone} </p>
                                                        </li>
                                                        <li>
                                                            <img src={Icon2} alt="" className="mCS_img_loaded" />
                                                            <h6 className="contact-title">E-mail Adres</h6>
                                                            <Link to="#">{item.Contact_MailAddress}</Link><br />

                                                        </li>
                                                        <li>
                                                            <img src={Icon3} alt="" className="mCS_img_loaded" />
                                                            <h6 className="contact-title">Adres</h6>
                                                            <p>Kılınçarslan Mahallesi Şair Seyfi Sokak No:18</p>
                                                        </li>
                                                    </ul>
                                                ))}
                                            </div>
                                        </aside>

                                    </div>

                                </div>

                            </div>
                        </div>



                    </div>

                </div>

                <Footer1 />

            </>
        );
    }
}

export default Partners;
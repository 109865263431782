import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import WhatsAppWidget from "react-whatsapp-chat-widget";
import "react-whatsapp-chat-widget/index.css";
import axios from "axios";
import { Provider } from 'mobx-react';
import { HashRouter } from "react-router-dom";

import Logo from "./images/logo2.png";
function MainApp() {
  const [about, setAuthors] = useState([]);

  useEffect(() => {
    axios.get('https://getjob.stechomeyazilim.info:5101/getContactAktif/select/')
      .then(res => {
        setAuthors(res.data[0]);
      })
      .catch(err => console.log(err));
  }, []);

  return (
    <React.StrictMode>
      <HashRouter>
        <Provider>
          <App />
      
  
          <WhatsAppWidget
            phoneNo={about.WhatsappNumber}
            position="right"
            widgetWidth="300px"
            widgetWidthMobile="260px"
            autoOpen={false}
            autoOpenTimer={5000}
            messageBox={true}
            messageBoxTxt="Merhaba, bize ne sormak istersiniz?"
            iconSize="60"
            iconColor="white"
            iconBgColor="#4dc247"
            headerIcon={Logo}
            headerIconColor="#c5aa77"
            headerTxtColor="white"
            headerBgColor="#4e4e4e"
            headerTitle="Aktif Güvenlik"
            headerCaption="Online"
            bodyBgColor="#bbb"
            chatPersonName="Destek"
            chatMessage={<>Merhaba 👋 <br /><br /> Size Nasıl Yardımcı Olabilirim?</>}
            footerBgColor="#999"
            placeholder="Mesaj Türü.."
            btnBgColor="#4e4e4e"
            btnTxt="Konuşmaya Başla"
            btnTxtColor="white"
          /> 
        </Provider>
      </HashRouter>
    </React.StrictMode>
  );
}
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import moment from 'moment';
import ClientLogo from '../elements/client-logo/client-logo-1';
import Brand from '../elements/client-logo/client-logo-2';
// Layout
import Header1 from '../layout/header/header1';
import Footer1 from '../layout/footer/footer1';

// Elements
import Slider from '../elements/slider/slider1';
import ServiceSlider1 from '../elements/services/service-slider-1';
import ServiceSlider2 from '../elements/services/service-slider-2';
import About from '../elements/about/about1';
import NumberBox from '../elements/number-box/number-box';
import Testimonial from '../elements/testimonial/testimonial1';

import Logo from "../../images/logo.png"
// Images

import axios from 'axios';


class Index extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			data2: [],
			data3: [],
			data5: [],
			imageUrl: 'https://aktifguvenlikapi.stechomeyazilim.info/upload/',
			searchText: '',
			searchResults: [],

		};
	}
	handleSearchInputChange = async (e) => {
		const searchText = e.target.value;
		this.setState({ searchText }, () => {
			if (searchText.trim() === '') {
				this.setState({ searchResults: [] });
			} else {
				this.handleSearch();
			}
		});
	}

	handleSearch = async () => {
		const { searchText } = this.state;

		try {
			const response = await axios.get(`https://getjob.stechomeyazilim.info:5101/getProductKeyword/select/${searchText}`);
			this.setState({ searchResults: response.data });
		} catch (error) {
			console.error('API isteği sırasında bir hata oluştu:', error);
		}
	}
	componentDidMount() {
		const apiUrl = "https://getjob.stechomeyazilim.info:5101/getSSSAktif/select/";

		fetch(apiUrl)
			.then((response) => response.json())
			.then((data) => {
				this.setState({ data });
			})
			.catch((error) => {
				console.error("API'den veri çekme hatası:", error);
			});

		const apiUrl2 = "https://getjob.stechomeyazilim.info:5101/getContactEzgican/select/";

		fetch(apiUrl2)
			.then((response) => response.json())
			.then((data2) => {
				this.setState({ data2 });
			})
			.catch((error) => {
				console.error("API'den veri çekme hatası:", error);
			});
		const apiUrl3 = "https://getjob.stechomeyazilim.info:5101/getBlogAktif/select/";

		fetch(apiUrl3)
			.then((response) => response.json())
			.then((data3) => {
				this.setState({ data3 });
			})
			.catch((error) => {
				console.error("API'den veri çekme hatası:", error);
			});
		const apiUrl5 = "https://getjob.stechomeyazilim.info:5101/getProductCategoryAktif/select/";

		fetch(apiUrl5)
			.then((response) => response.json())
			.then((data5) => {
				this.setState({ data5 });
			})
			.catch((error) => {
				console.error("API'den veri çekme hatası:", error);
			});

	}
	render() {
		const { searchText, searchResults } = this.state;
		return (
			<>

				<Header1 />

				{/* <!-- Content --> */}
				<div className="page-content bg-white">

					<Slider />

					{/* <!-- Main Slider --> */}


				</div>
				<div className="content-block" id="content-area">







					<div className="section-area process-area">
						<div className="container">
							<ServiceSlider1 />
						</div>
					</div>

					
					<div className="section-area section-sp3 bg-white">
						<div className="container">
							<About />
						</div>

					</div>




					<div className="section-area bg-white section-sp2 mt-8">
						<div className="container">
						<div className="col-lg-4 col-md-12">
									
								</div>
								<div className="row heading-bx align-items-end">
								<div className="col-lg-8 col-md-9">
									<h6 className="title-ext m-b0">ÜRÜNLERİMİZ</h6>
									<h2 className="title-head m-b0">Aktif Güvenlik Sistemleri Ürünler</h2>
									<div className="ttr-separator sepimg"></div>
								</div>
								<div className="col-lg-4 col-md-3">
									<Link to="urunler" className="btn float-right">Tüm Ürünler</Link>
								</div>
							</div>
							<div className="row">
							

								{this.state.data5.slice(0,1).map((item) => (
									item.ProductSubCategory.slice(0,1).map((item2) => (
										item2.Product.slice(0,3).map((item3) => (
										<Link to={{ pathname: '/urundetay', state: { _item: item3 } }} className="col-lg-4 col-md-6 col-sm-6">
											<div className="service-box text-center m-xs-b30">
												<div className="service-media">
													<img src={`https://aktifguvenlikapi.stechomeyazilim.info/upload/${item3.ProductImage}`} alt="" />
													<div className="service-inner">
														<Link to={{ pathname: '/urundetay', state: { _item: item3, } }}className="btn white">İncele 
														</Link>
													</div>
												</div>
												<div className="service-info">
													<h4 className="title">{item3.Product_Title}</h4>

												</div>
											</div>
										</Link>
									))
									))
								))}

							</div>
						</div>
					</div>



					<div className="section-area bg-white section-sp1 blog-area">
						<div className="container">
							<div className="row heading-bx align-items-end">
								<div className="col-lg-8 col-md-9">
									<h6 className="title-ext m-b0">BLOGLAR</h6>
									<h2 className="title-head m-b0">Aktif Güvenlik Sistemleri Blog Yazıları</h2>
									<div className="ttr-separator sepimg"></div>
								</div>
								<div className="col-lg-4 col-md-3">
									<Link to="blog" className="btn float-right">Tüm Bloglar</Link>
								</div>
							</div>
							<div className="row">

								{this.state.data3.slice(0, 1).map((item) => (
									<div className="col-md-6">
										<div className="recent-news">
											<div className="action-box">
												<img src={this.state.imageUrl + item.BlogImage} alt="" />
											</div>
											<div className="info-bx">
												<ul className="media-post">
													<li className="date"><Link to={{ pathname: '/blogdetay', state: { _item: item } }}><i className="fa fa-calendar-o"></i>{moment(item.Article_Date).format('YYYY-MM-DD')}</Link></li>
												</ul>
												<h3 className="post-title"><Link to={{ pathname: '/blogdetay', state: { _item: item } }}>{item.BlogTitle}</Link></h3>
											</div>
										</div>
									</div>
								))}
								<div className="col-md-6">
									<div className="side-post">



										{this.state.data3.slice(0, 3).map((item) => (

											<div className="blog-post blog-md clearfix wow fadeInUp" data-wow-delay="0.2s">
												<div className="ttr-post-media">
													<Link to={{ pathname: '/blogdetay', state: { _item: item } }}>	<img src={this.state.imageUrl + item.BlogImage} alt="" /></Link>
												</div>
												<div className="ttr-post-info">
													<ul className="media-post">
														<li className="date"><Link to={{ pathname: '/blogdetay', state: { _item: item } }}><i className="fa fa-calendar-o"></i>{moment(item.Article_Date).format('YYYY-MM-DD')}</Link></li>
													</ul>
													<h4 className="post-title"><Link to={{ pathname: '/blogdetay', state: { _item: item } }}>{item.BlogTitle}</Link></h4>
												</div>
											</div>
										))}
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="section-area section-sp2 bg-gray">
						<div className="container wow fadeIn" data-wow-delay="0.8s">
							<div className="heading-bx text-center">
								<h6 className="title-ext m-b0">Referanslarımız</h6>
								<h2 className="title-head m-b0">Referanslarımız & Müşterilerimiz</h2>
								<Link to='/partners'>Tümünü Gör</Link>


							</div>
							<ClientLogo />
						</div>
					</div>
					<div className="section-area section-sp2  " style={{ backgroundColor: '#0053A4' }}>
						<div className="container wow fadeIn" data-wow-delay="0.8s">
							<div className="heading-bx text-center">
								<h6 className="title-ext m-b0" style={{ color: 'white' }}>AKTİF GÜVENLİK SİSTEMLERİ</h6>
								<h2 className="title-head m-b0" style={{ color: 'white' }}>Markalarımız</h2>
								<Link style={{ color: 'white' }} to='/partners'>Tümünü Gör</Link>
							</div>
							<Brand />
						</div>
					</div>
				</div>

				<Footer1 />
			</>
		);
	}
}

export default Index;
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Sticky from 'react-stickynode';
import Logo from "../../../images/logo2.png"
import axios from 'axios';
class Header1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      data2: [],
      searchText: '',
      searchResults: [],

    };
  }
  handleSearchInputChange = async (e) => {
    const searchText = e.target.value;
    this.setState({ searchText }, () => {
      if (searchText.trim() === '') {
        this.setState({ searchResults: [] });
      } else {
        this.handleSearch();
      }
    });
  }

  handleSearch = async () => {
    const { searchText } = this.state;

    try {
      const response = await axios.get(`https://getjob.stechomeyazilim.info:5101/getProductKeyword/select/${searchText}`);
      this.setState({ searchResults: response.data });
    } catch (error) {
      console.error('API isteği sırasında bir hata oluştu:', error);
    }
  }

  componentDidMount() {


    const apiUrl = "https://getjob.stechomeyazilim.info:5101/getProductCategoryAktif/select/";

    fetch(apiUrl)
      .then((response) => response.json())
      .then((data2) => {
        this.setState({ data2 });
      })
      .catch((error) => {
        console.error("API'den veri çekme hatası:", error);
      });


    const apiUrl2 = "https://getjob.stechomeyazilim.info:5101/getContactAktif/select/";

    fetch(apiUrl2)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ data });
      })
      .catch((error) => {
        console.error("API'den veri çekme hatası:", error);
      });

    // Cart Dropdown 
    var cartBtn = document.getElementById("cartBtn");
    var cartDropdown = document.querySelector(".cart-dropdown");

    cartBtn.addEventListener('click', function () {
      cartDropdown.classList.toggle("show");
    });

    // Search Form Popup
    var searchBtn = document.getElementById("quik-search-btn");
    var searchForm = document.querySelector(".nav-search-bar");
    var closeBtn = document.getElementById("search-remove");

    searchBtn.addEventListener('click', function () {
      searchForm.classList.add("show");
    });

    closeBtn.addEventListener('click', function () {
      searchForm.classList.remove("show");
    });

    // Mobile Menu sidebar function
    var btn = document.querySelector('.menuicon');
    var nav = document.querySelector('.menu-links');

    function toggleFunc() {
      btn.classList.toggle("open");
      nav.classList.toggle("show");
    }

    btn.addEventListener('click', toggleFunc);

    // Mobile Submenu open close function
    var navMenu = [].slice.call(document.querySelectorAll('.menu-links > ul > li'));
    for (var y = 0; y < navMenu.length; y++) {
      navMenu[y].addEventListener('click', function () { menuClick(this) });
    }

    function menuClick(current) {
      const active = current.classList.contains("open")
      navMenu.forEach(el => el.classList.remove('open'));

      if (active) {
        current.classList.remove('open')
        console.log("active")
      } else {
        current.classList.add('open');
        console.log("close")
      }
    }
  }

  render() {
    const { searchText, searchResults } = this.state;

    return (
      <>
        <header className="header rs-nav">
          <div className="nav-search-bar">
            <form action="#">
              <input
                name="search"
                type="text"
                className="form-control"
                placeholder="Type to search"
                value={this.state.searchValue} // Bind the input value to the state
                onChange={this.handleSearchInputChange} // Update the state on input change
              />
              <span><i className="ti-search"></i></span>
            </form>
            <span id="search-remove"><i className="ti-close"></i></span>
          </div>
          <div className="top-bar">
            <div className="container">
              <div className="row d-flex justify-content-between">
                <div className="topbar-left">
                  {this.state.data.map((item) => (
                    <ul>
                      <li><a href={"tel:" + item.Contact_Phone}><i className="la la-phone"></i>Bizi Ara {item.Contact_Phone}</a></li>
                      <li><a href={"mailto:" + item.Contact_MailAddress}><i className="las la-envelope-open"></i>{item.Contact_MailAddress}</a></li>
                    </ul>
                  ))}
                </div>








              </div>
            </div>

          </div>

          <Sticky enabled={true} className="sticky-header navbar-expand-lg">
            <div className="menu-bar clearfix">
              <div className="container clearfix">
                <div className="menu-logo">
                  <Link to="/"><img src={Logo} alt="" /></Link>
                </div>
                <button className="navbar-toggler collapsed menuicon justify-content-end" type="button" data-toggle="collapse" data-target="#menuDropdown" aria-controls="menuDropdown" aria-expanded="false" aria-label="Toggle navigation">
                  <span></span>
                  <span></span>
                  <span></span>
                </button>
                <div className="secondary-menu">
                  <div className="secondary-inner">
                    <ul>
                      <li className="search-btn">
                        <button id="quik-search-btn" type="button" className="btn-link"></button>
                      </li>

                      <li>
                        <Link to="#" id="cartBtn"></Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="menu-links navbar-collapse collapse justify-content-end" id="menuDropdown">
                  <div className="menu-logo">
                    <Link to="/"><img src={Logo} alt="" /></Link>
                  </div>
                  <ul className="nav navbar-nav">
                    <li >
                      <Link to="/">Anasayfa</Link>
                    </li>

                    <li className="has-mega-menu">
                      <Link >Ürünler <i className="fa fa-chevron-down"></i></Link>
                      {this.state.data2 !== null ? (
                        <ul className="mega-menu">
                          {this.state.data2.map((item) => (
                            <li key={item.ID}>
                              <Link to="/urunler_">{item.Product_Category_Title} </Link>
                              <ul>
                                {item.ProductSubCategory !== null ? (
                                  item.ProductSubCategory.map((item2) => (
                                    <li key={item2.ID}>
                                      <Link to={{ pathname: 'urunler', state: { _item: item2 } }} >

                                        <span>{item2.Product_Category_Title}</span>
                                      </Link>
                                    </li>
                                  ))
                                ) : null}
                              </ul>
                            </li>
                          ))}
                        </ul>
                      ) : null}
                    </li>

                   
                    <li >
                      <Link to="/programlar">Programlar</Link>
                    </li>

                    <li >
                      <Link to="/referanslar">Referanslarımız</Link>
                    </li>
                    <li><Link to="#">Kurumsal<i className="fa fa-chevron-down"></i></Link>
                      <ul className="sub-menu">
                        <li className="add-menu-left">
                          <ul>

                            <li >
                              <Link to="/hakkimizda">Hakkımızda</Link>
                            </li>
                            <li >
                              <Link to="/sertifikalarimiz">Sertifikalarımız</Link>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li >
                      <Link to="/blog">Blog</Link>
                    </li>
                    <li >
                      <Link to="/iletisim">İletişim</Link>
                    </li>

                    <li >
                      <Link to="/iletisim">AHM Giriş</Link>
                    </li>





                  </ul>
                  {this.state.data.map((item) => (
                    <div className="nav-social-link">
                      <Link to={item.FacebookAdd}><i className="fa fa-facebook"></i></Link>
                      <Link to={item.TwitterAdd}><i className="fa fa-twitter"></i></Link>
                      <Link to={item.InstagramAdd}><i className="fa fa-instagram"></i></Link>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Sticky>
          <div className="nav-search-bar">
            <form action="#">
              <input name="search" type="text" className="form-control" placeholder="Type to search" />
              <span><i className="ti-search"></i></span>
            </form>
            <span id="search-remove"><i className="ti-close"></i></span>
          </div>

        </header>
      </>
    );
  }
}

export default Header1;

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import LogoWhite from "../../../images/logo-white.png";
import IconImg1 from "../../../images/icon/contact/icon1.png";
import IconImg2 from "../../../images/icon/contact/icon2.png";
import IconImg3 from "../../../images/icon/contact/icon3.png";

class Footer1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      data2: { ip: 'IP adresi alınamadı' },
    };
  }

  componentDidMount() {
    const apiUrl2 = "https://getjob.stechomeyazilim.info:5101/getContactAktif/select/";

    fetch(apiUrl2)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ data });
      })
      .catch((error) => {
        console.error("API'den veri çekme hatası:", error);
      });
      fetch('https://ipinfo.io/json')
      .then(response => response.json())
      .then(data2 => {
        this.setState({ data2 });
        console.log('Kullanıcının IP adresi:', data2.ip);
      })
      .catch(error => {
        console.error('IP adresi alınamadı:', error);
      });
    
  }

  render() {
    const { data } = this.state;

    return (
      <>
        <footer className="footer-style2">
          <div className="footer-top bt0">
            <div className="container">
              {data.map((item) => (
                <div className="row align-items-center footer-info" key={item.id}>
                  <div className="col-lg-3 col-md-6 col-sm-6 m-md-b30">
                    <div className="feature-container left footer-info-bx">

                      <div className="icon-content">

                        <p>Bugün: 22</p>
                        <p>Toplam: 22312132</p>
                        <p>IP Adresiniz: {this.state.data2.ip}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6 m-md-b30">
                    <div className="feature-container left footer-info-bx">
                      <div className="feature-lg text-white">
                        <span className="icon-cell"><img src={IconImg3} alt="" /></span>
                      </div>
                      <div className="icon-content">
                        <h5 className="ttr-tilte">Adres</h5>
                        <p>Kılınçarslan Mahallesi Şair Seyfi Sokak No:18</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6 m-md-b30">
                    <div className="feature-container left footer-info-bx">
                      <div className="feature-lg text-white">
                        <span className="icon-cell"><img src={IconImg1} alt="" /></span>
                      </div>
                      <div className="icon-content">
                        <h5 className="ttr-tilte">İletişim Numarası</h5>
                        <p>{item.Contact_Phone}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6 m-sm-b30">
                    <div className="feature-container left footer-info-bx">
                      <div className="feature-lg text-white">
                        <span className="icon-cell"><img src={IconImg2} alt="" /></span>
                      </div>
                      <div className="icon-content">
                        <h5 className="ttr-tilte">Email Address</h5>
                        <p>{item.Contact_MailAddress}</p>
                      </div>
                    </div>
                  </div>

                </div>
              ))}
            </div>
            {/* Rest of your footer content */}
          </div>
        </footer>
      </>
    );
  }
}

export default Footer1;
